import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { shortenName } from '../../../utils/manipulateString';
import EventProductCard from './EventProuctCard';
import { useAppSelector } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { setEventProduct } from '../../../redux/eventProductDetail';

export type LiveEventProductViewProps = {
	products: TGetAllProductsData[];
	brands?: TLiveEventAffiliatedBrand[];
	eventDetails?: TSingleLiveEventData;
};

const ProductView = ({ products, eventDetails }: LiveEventProductViewProps) => {
	const [eventProducts, setEventProducts] = useState(products || []);
	const eventProduct = useAppSelector(state => state.eventProduct);
	const dispatch = useDispatch();

	useEffect(() => {
		if (eventProduct?.product?._id) {
			const findHighlightProduct = eventProducts?.find(
				data => data?.isHighlightedProduct === true
			);
			const prevProducts = [...eventProducts];
			if (findHighlightProduct) prevProducts.shift();
			const newHighlightWithProducts = [
				{
					...eventProduct?.product?.associatedProduct,
					isHighlightedProduct: true,
				},
				...prevProducts,
			];
			setEventProducts(newHighlightWithProducts);
		} else {
			setEventProducts(products);
		}
	}, [eventProduct, products]);

	// console.log({ eventProducts });

	return (
		<View style={styles.container}>
			<ScrollView
				style={styles.productScrollContainer}
				showsHorizontalScrollIndicator={false}
				horizontal={true}
			>
				<View style={styles.productContainer}>
					{eventProducts?.map((product, index) => (
						<EventProductCard
							key={index}
							isHighlightedProduct={product?.isHighlightedProduct}
							price={
								product.variants[product?.defaultVariant ?? 0]
									?.option?.values[0]?.price
							}
							shortDesc={product?.shortDescription?.substring(
								0,
								20
							)}
							eventDetails={eventDetails}
							variant={
								product.variants[product?.defaultVariant ?? 0]
							}
							product={product}
							productName={shortenName(product.name, 35)}
							imageLink={
								product.variants[product?.defaultVariant ?? 0]
									?.image?.src
							}
						/>
					))}
				</View>
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// justifyContent: 'center',
	},
	productScrollContainer: {
		height: 90,
		width: '100%',
		overflow: 'scroll',
	},
	productContainer: {
		width: '50%',
		// overflow: 'scroll',
		flexDirection: 'row',
	},
});

export default ProductView;
