import { BlurView } from 'expo-blur';
import React, { useEffect } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import Animated, {
	useAnimatedStyle,
	useSharedValue,
	withSpring,
} from 'react-native-reanimated';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { LiveEventMessage } from '../product-section/event-chat/EventChatBox';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { FacebookIcon } from '../../common/icons';
type Props = {
	message: LiveEventMessage;
};

const ChatMessageWrapper = styled.View`
	width: max-content;
	max-width: 100%;
	// margin: 2.5rem 0;
`;

const EventChatMessage = ({ message }: Props) => {
	const offset = useSharedValue(0);

	const defaultSpringStyles = useAnimatedStyle(() => {
		return {
			transform: [{ translateY: withSpring(offset.value * 255) }],
		};
	});

	useEffect(() => {
		offset.value = offset.value + -0.08;
	}, []);


	return (
		<Animated.View style={[defaultSpringStyles, { marginBottom: 5 }]}>
			{/* <Image
				style={styles.image}
				source={{
					uri: message?.user?.personalInformation?.profileImageLink,
				}}
			/> */}
			<View style={styles.container}>
				{/* {message?.user?.personalInformation?.profileImageLink ? (
					<Image
						style={styles.image}
						source={{
							uri: message?.user?.personalInformation
								?.profileImageLink,
						}}
					/>
				) : (
					<Image
						style={styles.image}
						source={require('../../../assets/defaultProfileImage.svg')}
					/>
				)} */}
				<BlurView
					intensity={30}
					tint={'light'}
					style={[styles.textContainer]}
				>
					<View style={{ alignSelf: 'flex-start' }}>
						<Text style={styles?.name}>
							{message?.user?.username}
							{message.from === "Facebook" && <FacebookIcon
								height={getResponsiveStyle(
									12,
									'dimensions'
								)}
								width={getResponsiveStyle(
									12,
									'dimensions'
								)}
							/>}
							:
						</Text>
					</View>
					<Text style={styles.text}>{message?.message?.message}</Text>
				</BlurView>
			</View>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	flex1: {
		flex: 1,
		// flexDirection: 'row',
		justifyContent: 'flex-start',
		marginTop: 15,
	},
	name: {
		fontSize: 15,
		fontWeight: '500',
	},
	textContainer: {
		// width: 123,
		// height: 30,
		display: 'flex',
		// flexDirection: 'row',
		width: 'max-content',
		paddingRight: 15,
		paddingLeft: 15,
		paddingTop: 10,
		paddingBottom: 10,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgb(5 1 1 / 44%);',
		// borderTopLeftRadius: 0,
		// borderTopRightRadius: 50,
		// borderBottomRightRadius: 50,
		// borderBottomLeftRadius: 50,
		borderRadius: 6,
	},
	text: {
		fontSize: 13,
		fontWeight: '400',
		lineHeight: 18,
		maxWidth: 200,
		// width: 'max-content',
		fontStyle: 'normal',
		color: palettes.light[0],
		alignSelf: 'flex-start',
	},
	image: {
		width: 32,
		height: 32,
		marginRight: 10,
		borderRadius: 9999,
		alignSelf: 'flex-start',
	},
	container: {
		// borderWidth: 2,
		// borderColor: palettes.grey[4],
		// height: 250,
		// width: '100%',
		// overflow: 'scroll',
		flexDirection: 'row',
	},
});

export default EventChatMessage;
