import { useLazyQuery, useMutation } from '@apollo/client';
import {
	useFocusEffect,
	useNavigation,
	useRoute,
} from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import {
	DesktopNavigationBar,
	Loader,
	MobileNavigationBarTop,
} from '../components';
import LiveEventSection from '../components/live-event/event-section/';
import { isAccountHostOrSeller } from '../components/live-event/event-section/utils/LiveEventSection.utils';
import ProductSection from '../components/live-event/product-section/ProductSection';
import { palettes } from '../config';
import { START_LIVE_EVENT } from '../graphql/events/mutations';
import { GET_SINGLE_LIVE_EVENT_DETAILS } from '../graphql/events/queries';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { useSetAlert } from '../hooks/useSetAlerts';
import { setLiveEventAudience } from '../redux/chatSlice';
import { useAppSelector } from '../redux/store';
import { TUseNavigation, TUseRoute } from '../types/exportedTypes';
import { TStyle } from '../types/TStyle';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import { isAssociatedWithEvent } from '../utils/isAssociatedWithEvent';
import { useGetSingleBrandLazy } from '../api/brands/useGetSingleBrand';
import { LiveEventMessage } from '../components/live-event/product-section/event-chat/EventChatBox';
// import { socket } from '../../App';
import useGetLiveEventMessage from '../api/chat/useGetLiveEventMessage';
import {
	addDoc,
	collection,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	Timestamp,
} from 'firebase/firestore';
// import EventSource from 'react-native-event-source';
import { db } from '../services/firebase';
import { showLocksToUnInvitedUser } from '../redux/getEventSlice';
import { setCurrentScreen } from '../redux/uiSlice';
import { setEventProduct } from '../redux/eventProductDetail';
import AnonymousChatModal from '../components/common/anonymous-chat/Anonymous';
import AsyncStorage from '@react-native-community/async-storage';
import EmailCollection from '../components/common/email-collection/EmailCollection';
import axios from 'axios';

const isAffiliatedSeller = (userId: string, sellerId: string) => {
	return userId === sellerId;
};

const getHostIds = (hosts: TLiveEventAffiliatedHosts[]) => {
	return hosts?.map(host => host?._id);
};

const isAffiliatedHost = (userId: string, hostIds: string[]) => {
	return hostIds?.includes(userId);
};

const isAffiliatedHostOrSeller = (
	loggedInUserDetails: TCommonResponseData | undefined,
	event: TSingleLiveEventData
) => {
	if (
		!loggedInUserDetails ||
		!isAccountHostOrSeller(loggedInUserDetails.typeOfAccount)
	) {
		return false;
	}

	const userId = loggedInUserDetails._id;

	const typeOfAccount = loggedInUserDetails.typeOfAccount;

	return typeOfAccount === 'HOST'
		? isAffiliatedHost(userId, getHostIds(event.affiliatedHosts))
		: isAffiliatedSeller(userId, event?.affiliatedSeller?._id);
};

const getProductsFromBrands = (brands: TLiveEventAffiliatedBrand[]) => {
	return brands.map(brand => brand.brandProducts).flat();
};

//https://stackoverflow.com/questions/11642926/stop-close-webcam-stream-which-is-opened-by-navigator-mediadevices-getusermedia
export const stopVideoAndAudio = async (stream: MediaStream) => {
	stream?.getTracks()?.forEach(function (track) {
		track?.stop();
	});
};

// export const getMediaStream = async () => {
// 	return await navigator.mediaDevices.getUserMedia({
// 		audio: true,
// 		video: true,
// 	});
// };

const LiveEventScreen = () => {
	const navigation = useNavigation<TUseNavigation>();
	const { params } = useRoute<TUseRoute<'LiveEvent'>>();
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();
	const { shouldHideScreen } = useHandleUnmount();
	const [showUsernameModal, setShowUsernameModal] = useState(false);
	const [showEmailCollectionModal, setShowEmailCollectionModal] =
		useState(false);
	const [messages, setMessages] = useState<LiveEventMessage[]>([]);
	const [username, setUsername] = useState('');
	const [visitorEmail, setVisitorEmail] = useState('');
	const [liveStreamAccessToken, setLiveStreamAccessToken] = useState('');
	const [highlightedEventProducts, setHighlightedEventProducts] = useState<
		any[]
	>([]);
	const [highlightedEventProductsTime, setHighlightedEventProductsTime] =
		useState<number[]>([]);
	const [lastStoreEventProductTime, setLastStoreEventProductTime] =
		useState(0);
	const [tempMessagesStore, setTempMessagesStore] = useState<
		LiveEventMessage[]
	>([]);

	const { executeGetLiveEventMessage, data: liveEventMessage } =
		useGetLiveEventMessage();

	useEffect(() => {
		dispatch(setEventProduct({}));
		executeGetLiveEventMessage({
			eventId: params.eventId,
		});
	}, []);

	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));

	const showLocks = useAppSelector(state => state.getEvent.showLocks);
	const eventProduct = useAppSelector(state => state.eventProduct);
	const { indexOfPrimaryColors } = useAppSelector(state => state.ui);

	const isLoggedInTypeOfAccount =
		isUserLoggedIn && indexOfPrimaryColors === 0
			? 'CONSUMER'
			: indexOfPrimaryColors === 3
				? 'SELLER'
				: indexOfPrimaryColors === 2
					? 'HOST'
					: null;

	// console.log({
	// 	typeOfRequestAccount: loggedInUserDetails?.typeOfAccount,
	// 	isLoggedInTypeOfAccount,
	// });

	const [liveEventDetails, setLiveEventDetails] =
		useState<TSingleLiveEventData>();

	const [products, setProducts] = useState<TGetAllProductsData[]>([]);

	const onCompletedGetSingleBrandDetails = (
		data: TGetSingleBrandResponse
	) => {
		if (data.getBrand.success && data.getBrand.data) {
			setProducts(data?.getBrand?.data?.products?.data);
		}
	};

	const { getSingleBrand, loading } = useGetSingleBrandLazy({
		onCompleted: onCompletedGetSingleBrandDetails,
	});
	useEffect(() => {
		const brandId = liveEventDetails?.affiliatedBrands?.[0]?._id;
		if (brandId) getSingleBrand(brandId);
	}, [liveEventDetails]);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/live-event'));
		dispatch(setEventProduct({}));
		const getAnonymousUsername = async () => {
			const value = await AsyncStorage.getItem('anonymousUsername');
			const email = await AsyncStorage.getItem('anonymousEmail');
			setUsername(value as string);
			setVisitorEmail(email as string);
		};
		getAnonymousUsername();
	}, []);

	useEffect(() => {
		if ((liveEventDetails?.affiliatedSeller?._id === loggedInUserDetails?._id) && liveStreamAccessToken) {
			setInterval(() => {
				axios
					.get(
						`https://graph.facebook.com/${liveEventDetails?.socialStreamKey?.facebookStreamKey?.id}/comments?access_token=${liveStreamAccessToken}`
					)
					.then(async data => {
						// @ts-ignore
						const msgsRef = collection(db, 'messages', params.eventId, 'LiveChat');
						const q = query(msgsRef, orderBy('createdAt', 'asc'));
						const querySnapshot = await getDocs(q);
						let messages: LiveEventMessage[] = [];

						querySnapshot.forEach(doc => {
							const data = doc.data();
							if (data) {
								messages.push(data as LiveEventMessage);
							}
						});

						const getNewMessages = data?.data?.data?.filter((chat: TODO) => messages.findIndex((m: TODO) => m?.id === chat?.id) === -1)?.map((newMessage) => {
							return {
								...newMessage,
								user: newMessage?.from
							}
						})

						getNewMessages.forEach((message: any) => {
							addDoc(
								// @ts-ignore
								collection(db, 'messages', liveEventDetails?._id, 'LiveChat'),
								{
									message: {
										message: message.message || "",
										messageType: "TEXT"
									},
									user: {
										id: message?.user?.id || "",
										username: message?.user?.name || "Facebook User"
									},
									from: "Facebook",
									id: message.id || "",
									roomMessage: true,
									createdAt: Timestamp.fromDate(new Date()),
								}
							);
						});
					})
					.catch(error => {
						console.log({ commentError: error });
					})
			}, 5000);
		}
		// const eventSource = new EventSource(`https://streaming-graph.facebook.com/2446835912192690/live_comments?access_token=${access_token}`);

		// eventSource.addEventListener('message', (event) => {
		// 	const newComment = JSON.parse(event.data);
		// 	console.log({ newComment });

		// 	// setComments((prevComments) => [...prevComments, newComment]);
		// });

		// return () => {
		// 	eventSource.close();
		// };
	}, [liveStreamAccessToken]);


	useEffect(() => {
		const userMessages = messages?.filter(
			data => data?.user?.username === username
		);
		if (userMessages.length === 5 && !visitorEmail) {
			setShowEmailCollectionModal(true);
		}
	}, [messages]);

	useEffect(() => {
		const msgsRef = collection(db, 'messages', params.eventId, 'LiveChat');
		const q = query(msgsRef, orderBy('createdAt', 'asc'));

		onSnapshot(q, querySnapshot => {
			let messages: LiveEventMessage[] = [];
			querySnapshot.forEach(doc => {
				messages.push(doc.data() as LiveEventMessage);
			});
			if (!liveEventDetails) return;
			const isEventEnded =
				liveEventDetails?.deviceMetadata?.streamRecordingInstance &&
				liveEventDetails?.eventStreamTimeline?.endedAt;
			if (!!isEventEnded) {
				setTempMessagesStore(messages);
			} else {
				setMessages(messages);
			}
		});
	}, [liveEventDetails]);

	//TODO: update the data on the backend

	useEffect(() => {
		if (liveEventMessage?.getLiveEventMessages?.data) {
			// const restructureObjArr =
			// 	liveEventMessage?.getLiveEventMessages?.data?.map(data => {
			// 		return {
			// 			...data,
			// 			message: { message: data.message, messageType: 'TEXT' },
			// 			user: data.sender,
			// 		};
			// 	});
			// if (!liveEventDetails) return;
			// const isEventEnded =
			// 	liveEventDetails?.deviceMetadata?.streamRecordingInstance &&
			// 	liveEventDetails?.eventStreamTimeline?.endedAt;
			// if (!!isEventEnded) {
			// 	setTempMessagesStore(restructureObjArr);
			// } else {
			// 	setMessages(restructureObjArr);
			// }
		}
	}, [liveEventMessage]);

	const onTimeUpdate = (event: any) => {
		const videoElement = event.target;
		const currentTimeInSeconds = Math.floor(videoElement.currentTime);
		const liveStreamStart = liveEventDetails?.eventStreamTimeline
			?.startedAt as any;
		const startStreamTimeWithCurrent =
			Math.floor(+liveStreamStart / 1000) + currentTimeInSeconds;
		// const findMessageInTime = tempMessagesStore?.find(
		// 	(data: LiveEventMessage) => {
		// 		return data?.createdAt?.seconds === startStreamTimeWithCurrent;
		// 	}
		// );

		const findMessageAtAnyTime = tempMessagesStore?.filter(
			(data: LiveEventMessage) =>
				data?.createdAt?.seconds <= startStreamTimeWithCurrent
		);

		const findCurrentHighlightProduct = highlightedEventProducts?.find(
			data => {
				return data?.createdAt?.seconds === startStreamTimeWithCurrent;
			}
		);

		// const findMessageInTimeIndex = tempMessagesStore?.findIndex(
		// 	(data: LiveEventMessage) => {
		// 		return data?.createdAt?.seconds === startStreamTimeWithCurrent;
		// 	}
		// );

		if (findMessageAtAnyTime.length > messages.length) {
			// setMessages((prevMessages: LiveEventMessage[]) => {
			// 	const isMessageAlreadyAdded = prevMessages?.find(
			// 		(data: LiveEventMessage) =>
			// 			data?.createdAt?.seconds +
			// 				data?.createdAt?.nanoseconds ===
			// 			findMessageInTime?.createdAt?.seconds +
			// 				findMessageInTime?.createdAt?.nanoseconds
			// 	);
			// 	if (isMessageAlreadyAdded) return prevMessages;
			// 	return [...prevMessages, findMessageInTime];
			// });
			setMessages(findMessageAtAnyTime);
		}
		if (findCurrentHighlightProduct) {
			const storedTime =
				findCurrentHighlightProduct?.createdAt?.seconds +
				findCurrentHighlightProduct?.createdAt?.nanoseconds;
			setLastStoreEventProductTime(storedTime);
			setTimeout(() => {
				if (lastStoreEventProductTime !== storedTime) {
					dispatch(setEventProduct({}));
				}
			}, 100);
			setTimeout(() => {
				// console.log({ products, highlightedEventProducts: findCurrentHighlightProduct?.eventProduct });

				const getOnlyProductVariants = products?.map((data) => data?.variants)?.flat()?.find(variant => variant.GTIN === findCurrentHighlightProduct?.eventProduct?.GTIN)

				console.log({
					updatedProductToUser: {
						associatedProduct: findCurrentHighlightProduct?.eventProduct
					},
					...getOnlyProductVariants,
					products
				});


				if (lastStoreEventProductTime !== storedTime) {
					dispatch(
						setEventProduct({
							...getOnlyProductVariants,
							associatedProduct: findCurrentHighlightProduct?.eventProduct?.associatedProduct
						})
					);
				}
			}, 500);
		}
	};

	useFocusEffect(
		useCallback(() => {
			if (loggedInUserDetails?.typeOfAccount === 'CONSUMER') {
				const loggedInUserId = loggedInUserDetails?._id;
				const invitedUsers = liveEventDetails?.invitedPeople?.map(
					user => user._id
				);
				const isUserInvited =
					invitedUsers?.length &&
					invitedUsers.includes(loggedInUserId);
				// setting show locks to false, as means to remove subscribe feature temporarily,
				// dispatch(showLocksToUnInvitedUser(!isUserInvited));
				dispatch(showLocksToUnInvitedUser(false));
			}
		}, [liveEventDetails])
	);

	const handleErrorFetchingEventDetails = () => {
		setAlert(t('Unable to fetch event details!'), 'danger');
		navigation.navigate('Events');
	};

	const [
		startLiveEvent,
		{ loading: loadingStartLiveEvent, called: startLiveStreamCalled },
	] = useMutation<TStartLiveEventResponse, TStartLiveEventInputs>(
		START_LIVE_EVENT,
		{
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: GET_SINGLE_LIVE_EVENT_DETAILS,
					variables: {
						_id: params.eventId ?? '',
						typeOfAccount:
							loggedInUserDetails?.typeOfAccount as TTypeOfAccount,
					},
				},
			],
			onCompleted: data => {
				if (!data.startLiveEvent) {
					setAlert(t('An error occurred!'), 'danger');
					navigation.navigate('Events');
				} else {
					getSingleEventDetails({
						variables: {
							_id: params.eventId ?? '',
							typeOfAccount:
								loggedInUserDetails?.typeOfAccount as TTypeOfAccount,
						},
					});
				}
			},
			onError: () => {
				setAlert(t('An error occurred!'), 'danger');
				navigation.navigate('Events');
			},
		}
	);

	useEffect(() => {
		getSingleEventDetails({
			variables: {
				_id: params.eventId ?? '',
				typeOfAccount:
					loggedInUserDetails?.typeOfAccount as TTypeOfAccount,
			},
		});
	}, [params.eventId]);

	const [
		getSingleEventDetails,
		{ loading: loadingGetSingleLiveEventDetails },
	] = useLazyQuery<TSingleLiveEventResponse, TSingleLiveEventInputs>(
		GET_SINGLE_LIVE_EVENT_DETAILS,
		{
			nextFetchPolicy: 'cache-first',
			onCompleted: async data => {
				if (data.getEvent.data && data.getEvent.success) {
					const event = data.getEvent.data;

					// unnecessary because we cannot route to a specific
					// page directly from url in React Native Web.
					// This would have been required in plain react

					// const isCurrentTimeGreaterThanBuyingPeriod =
					// 	currentTimeGreaterThanBuyingPeriod(
					// 		event.startingEventDateTime
					// 	);

					// if (isCurrentTimeGreaterThanBuyingPeriod) {
					// 	navigation.navigate('EventDescription', {
					// 		eventId: event._id,
					// 	});
					// }

					if (isAffiliatedHostOrSeller(loggedInUserDetails, event)) {
						if (
							event.isStreamGenerated &&
							event?.liveStream?.stream?.channel?.arn
						) {
							const products = getProductsFromBrands(
								event.affiliatedBrands
							);

							setProducts(products);
						} else {
							if (!startLiveStreamCalled) {
								startLiveEvent({
									variables: {
										_id: params.eventId ?? '',
									},
								});
								setLiveEventDetails(event);
							}
						}
					} else if (
						loggedInUserDetails?.typeOfAccount === 'CONSUMER' ||
						!loggedInUserDetails?.typeOfAccount
					) {
						const products = getProductsFromBrands(
							event.affiliatedBrands
						);

						setProducts(products);
						setLiveEventDetails(event);
					} else {
						// const stream = await getMediaStream();
						// stopVideoAndAudio(stream);
						setAlert(t('Not allowed!'), 'danger');
						navigation.navigate('Events');
					}
					setLiveEventDetails(event);
				} else {
					handleErrorFetchingEventDetails();
				}
			},
			onError: () => {
				handleErrorFetchingEventDetails();
			},
		}
	);

	// useEffect(() => {
	//     // window.addEventListener('beforeunload', alertUser)
	//     window.addEventListener('unload', handleTabClosing)
	//     return () => {
	//         // window.removeEventListener('beforeunload', alertUser)
	//         window.removeEventListener('unload', handleTabClosing)
	//     }
	// })

	// const handleTabClosing = () => {
	//     removePlayerFromGame()
	// }

	useEffect(() => {
		if (liveEventDetails) {
			// isAssociatedWithEvent(liveEventDetails, loggedInUserDetails) &&
			// 	socket.on('liveEventRoomData', ({ roomData }) => {
			// 		dispatch(setLiveEventAudience(roomData));
			// 	});
			// if (isAssociatedWithEvent(liveEventDetails, loggedInUserDetails)) {
			//@ts-ignore
			const liveAudienceRef = collection(
				db,
				'eventAudience',
				params.eventId,
				'LiveEventAudience'
			);
			const q = query(liveAudienceRef);

			onSnapshot(q, querySnapshot => {
				//@ts-ignore
				let liveEventAudience = [];
				querySnapshot.forEach(doc => {
					liveEventAudience.push(doc.data());
				});
				//@ts-ignore
				dispatch(setLiveEventAudience(liveEventAudience));
			});
			// }
		}
	}, [liveEventDetails]);

	useEffect(() => {
		if (liveEventDetails) {
			const isEventEnded =
				liveEventDetails?.deviceMetadata?.streamRecordingInstance &&
				liveEventDetails?.eventStreamTimeline?.endedAt;
			const liveStreamStart = liveEventDetails?.eventStreamTimeline
				?.startedAt as any;
			const startStreamTimeWithCurrent = Math.floor(
				+liveStreamStart / 1000
			);
			//@ts-ignore
			const eventHighLightProductRef = collection(
				db,
				'eventSelectedProduct',
				params.eventId,
				'product'
			);
			const q = query(eventHighLightProductRef);

			onSnapshot(q, querySnapshot => {
				let highlightedProducts: Variant[] = [];
				const changes = querySnapshot.docChanges();
				querySnapshot.forEach(doc => {
					highlightedProducts.push(doc.data() as Variant);
				});
				if (
					!isEventEnded &&
					loggedInUserDetails?.typeOfAccount !== 'SELLER'
				) {
					if (changes?.length === 1) {
						setTimeout(() => {
							dispatch(setEventProduct({}));
						}, 100);
						setTimeout(() => {
							dispatch(
								setEventProduct(
									highlightedProducts[changes?.[0]?.newIndex]
										?.eventProduct
								)
							);
						}, 1000);
					}
				}
				setHighlightedEventProducts(highlightedProducts);
				setHighlightedEventProductsTime(
					highlightedProducts?.map(
						data =>
							data?.createdAt?.seconds -
							startStreamTimeWithCurrent
					)
				);
			});
		}
	}, [liveEventDetails]);

	const handleLiveAudience = async () => {
		const vId = await AsyncStorage.getItem('vId');
		const visitorUser = {
			userId: vId,
			room: params?.eventId,
			userDetails: {
				username: username || 'visitor',
			},
		};
		const user = {
			userId: loggedInUserDetails?._id,
			room: params.eventId,
			userDetails: loggedInUserDetails,
		};

		try {
			await addDoc(
				//@ts-ignore
				collection(
					db,
					'eventAudience',
					params.eventId,
					'LiveEventAudience'
				),
				isUserLoggedIn ? user : visitorUser
			);
		} catch (error) {
			console.log({ error }, 'why it failed');
		}
	};

	const liveEventAudience = useAppSelector(
		state => state.chat.liveEventAudience
	);

	useEffect(() => {
		AsyncStorage.getItem('vId').then(id => {
			// console.log({ liveEventAudience, id });
			const isUserAlreadyInEvent = liveEventAudience.find(
				data => data.userId === (loggedInUserDetails?._id || id)
			);
			if (
				// params.eventId &&
				liveEventDetails &&
				!isUserAlreadyInEvent
			) {
				// if (isAssociatedWithEvent(liveEventDetails, loggedInUserDetails) || !loggedInUserDetails?.typeOfAccount) {
				handleLiveAudience();
				// }
			}
		});
	}, [loggedInUserDetails?._id, liveEventDetails]);

	// useEffect(() => {
	// 	if (loggedInUserDetails?._id && params.eventId && liveEventDetails) {
	// 		// if (isAssociatedWithEvent(liveEventDetails, loggedInUserDetails)) {
	// 		// 	handleLiveAudience();
	// 		// }

	// 		isAssociatedWithEvent(liveEventDetails, loggedInUserDetails) &&
	// 			socket.emit(
	// 				'joinEvent',
	// 				{
	// 					userId: loggedInUserDetails?._id,
	// 					room: params.eventId,
	// 					userDetails: loggedInUserDetails,
	// 				},
	// 				({
	// 					roomData,
	// 					isAlreadyInRoom,
	// 				}: {
	// 					roomData: RoomData[];
	// 					isAlreadyInRoom: boolean;
	// 				}) => {
	// 					if (isAlreadyInRoom) {
	// 						// setAlert(
	// 						// 	t('You are already in this room!'),
	// 						// 	'danger'
	// 						// );
	// 						// navigation.navigate('Events');
	// 					}
	// 					dispatch(setLiveEventAudience(roomData));
	// 				}
	// 			);
	// 	}
	// }, [loggedInUserDetails?._id, liveEventDetails]);
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return shouldHideScreen ? null : (
		<View style={{ position: 'relative' }}>
			{getSingleLoadingState(
				loadingGetSingleLiveEventDetails,
				loadingStartLiveEvent
			) ? (
				<Loader fullView />
			) : (
				liveEventDetails && (
					<View style={styles.container}>
						{showUsernameModal && !username && (
							<View style={{ position: 'absolute' }}>
								<AnonymousChatModal
									onPress={data => {
										setShowUsernameModal(false);
										//@ts-ignore
										setUsername(data);
									}}
									isOpen={showUsernameModal}
								/>
							</View>
						)}

						{showEmailCollectionModal &&
							username &&
							!isUserLoggedIn && (
								<View style={{ position: 'absolute' }}>
									<EmailCollection
										onPress={data => {
											setShowEmailCollectionModal(false);
											setVisitorEmail(data);
										}}
										isOpen={showEmailCollectionModal}
									/>
								</View>
							)}
						{!isLessThanDesktopBase && (
							<View
								style={{
									paddingLeft: 20,
									paddingRight: 20,
									paddingTop: 15,
									zIndex: 99999,
								}}
							>
								{!isLessThanDesktopBase && (
									<DesktopNavigationBar
										containerStyles={styleNavDesktop(true)}
										hasLinks={false}
										hasSearch={false}
										hasGoBack={true}
										logoLocation={'center'}
									/>
								)}
								{isLessThanDesktopBase && (
									<MobileNavigationBarTop
										containerStyles={styleNavMobile(true)}
									/>
								)}
							</View>
						)}
						<View
							style={{
								flexDirection: 'row',
								height: 'calc(100% - 135px)',
							}}
						>
							<LiveEventSection
								about={liveEventDetails.description}
								products={products}
								showLocks={showLocks}
								messages={messages}
								onTimeUpdate={onTimeUpdate}
								setLiveStreamAccessToken={setLiveStreamAccessToken}
								highlightedEventProductsTime={
									highlightedEventProductsTime
								}
								handleChange={() => {
									if (!isUserLoggedIn) {
										setShowUsernameModal(true);
									}
								}}
								// handleTypingFocusIn={() => {
								// 	if (!isUserLoggedIn) {
								// 		setShowUsernameModal(true)
								// 	}
								// }}
								//@ts-ignore
								eventDetails={liveEventDetails}
								userId={liveEventDetails.affiliatedSeller._id}
								liveStream={liveEventDetails.liveStream}
								username={
									liveEventDetails.affiliatedSeller.username
								}
								userDescription={
									liveEventDetails?.affiliatedSeller
										?.personalInformation?.description
								}
								userProfileImageLink={
									liveEventDetails.affiliatedSeller
										.personalInformation
										?.profileImageLink ?? ''
								}
								hostDetails={
									liveEventDetails.affiliatedHosts[0]
								}
								liveEventId={params.eventId}
							/>
							{!isLessThanDesktopBase && (
								//@ts-ignore
								<ProductSection
									showLocks={showLocks}
									messages={messages}
									brands={liveEventDetails.affiliatedBrands}
									products={products}
									eventDetails={liveEventDetails}
									handleChange={() => {
										if (!isUserLoggedIn) {
											setShowUsernameModal(true);
										}
									}}
								// handleTypingFocusIn={() => {
								// 	if (!isUserLoggedIn) {
								// 		setShowUsernameModal(true)
								// 	}
								// }}
								/>
							)}
						</View>
					</View>
				)
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		width: '100vw',
		height: '100vh',
	},
	image: {
		width: '100%',
		height: '480px',
		resizeMode: 'cover',
		borderRadius: 6,
	},
	layout: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		// marginTop: 40,
	},
	content: {
		flex: 1,
	},
	layoutDivider: {
		width: 1,
		height: '100%',
		backgroundColor: palettes.grey[5],
	},
	sidebar: {
		// width: 312,
		flexBasis: 312,
		flexGrow: 0,
		flexShrink: 0,
		// flex: "0 0 312px"
	},
});

export default LiveEventScreen;

const styleNavMobile = (hasPaddingRight: boolean): TStyle => {
	return {
		paddingRight: hasPaddingRight ? 0 : 16,
	};
};

const styleNavDesktop = (hasPaddingRight: boolean): TStyle => {
	return {
		paddingRight: hasPaddingRight ? 0 : 40,
	};
};
